











































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { PlanbookStorage } from '@/common/planbook-storage';
import SchoolStatusDialog from '@/components/core/SchoolStatusDialog.vue';
import SchoolYearSelector from '@/components/core/SchoolYearSelector.vue';
import BaseToolbar from '@/components/core/BaseToolbar.vue';
import LessonManageStatus from '@/components/lessons/LessonManageStatus.vue';
import Teachers from '@/store/modules/teachers';
import CommonUtils from '@/utils/common-utils';
import DateTimeUtils from '@/utils/date-time-utils';
import moment from 'moment';
import { Component, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import PrintOptions from '@/components/prints/PrintOptions.vue';
import Confirm from '@/components/core/Confirm.vue';

const settings = namespace('settings');
const schoolstatus = namespace('schoolstatus');
const plans = namespace('plans');
const drawer = namespace('drawer');
const teachers = namespace('teachers');

@Component({
  components: {
    PrintOptions,
    SchoolStatusDialog,
    LessonManageStatus,
    BaseToolbar,
    SchoolYearSelector
  }
})
export default class PlansToolbar extends BaseToolbar {
  gotoDateMenu = false;
  localAppBarDisabled = false;
  localPlansToolbarHidden = true;
  localPrintButtonHidden = true;
  selectedTeacher = 0;
  showStatusMenu = false;

  $refs!: {
    printOptions: PrintOptions,
    manageStatus: LessonManageStatus,
    applyStatus: SchoolStatusDialog,
    confirm: Confirm,
    datePicker: any
  };

  @settings.Getter
  dualUser!: string;

  @settings.State
  studentTeachers!: Array<any>;

  @settings.Getter('getSettings')
  settings!: any;

  @settings.Getter('getStudentTeacherItems')
  studentTeacherItems!: any[];

  @teachers.Getter('getAdminYearItems')
  getAdminYearItems!: (teacherId: number) => any[];

  @settings.Getter('getSchoolYearItems')
  teacherSchoolYearItems!: any[];

  @settings.Getter('getDateFormat')
  dateFormat!: number;

  @settings.Getter('getSchoolStatuses')
  allSchoolStatus!: Array<any>;

  @settings.Getter('getSchoolId')
  schoolId!: number;

  @settings.Getter
  getCurrentYear!: any;

  @settings.Getter('getGlobalLanguage')
  globalLanguage!: any;

  @plans.State
  viewType!: string;

  @plans.State
  month!: string;

  @plans.State
  currentDay!: string;

  @plans.Getter('getWeekDateRange')
  weekDateRange!: any;

  @plans.Getter('getCycleDateRange')
  cycleDateRange!: any;

  @plans.Getter('getCyclePages')
  cyclePages!: any[];

  @plans.Getter
  getWeekSchoolStatus!: any[];

  @plans.Getter
  getCycleScheduleDateStartEnd!:any;

  @plans.State
  cyclePage!: number;

  @plans.Action
  prevLessonsEvents!: (param?: any) => Promise<any>;

  @plans.Action
  gotoLessonsEvents!: (param: Date) => Promise<any>;

  @plans.Action
  nextLessonsEvents!: (param?: any) => Promise<any>;

  @plans.Action
  changeViewType!: (viewType?: string) => Promise<any>;

  @teachers.Getter
  isCurrentTeacherCycleSchedule!: number;

  @teachers.Getter
  currentSelectedSchoolYear!: any;

  @drawer.Mutation
  setDrawerBottom!: (v: boolean) => void;

  @drawer.State
  remindersMobileClickCounter!: number;

  @drawer.Mutation
  setRemindersMobileDrawer!: (v: boolean) => void;

  @drawer.Mutation
  setRemindersMobileClickCounter!: (v: number) => void;

  @plans.Action
  changeCyclePage!: (param?: number) => Promise<any>;

  @settings.Getter
  isCycleSchedule!: boolean;

  @schoolstatus.Action
  applySchoolStatus!: (params?: any) => Promise<any>;

  @schoolstatus.Action
  removeSchoolStatus!: (params?: any) => Promise<any>;

  get pickerGotoDate() {
    if (CommonUtils.hasText(this.currentDay)) {
      return moment(this.currentDay, 'MM/DD/YYYY').format('YYYY-MM-DD');
    }
    return '';
  }

  get darkMode(): boolean {
    return this.$vuetify.theme.dark;
  }

  get withBadge():boolean {
    return ((this.hasNewAdminComment && this.userMode === 'T') || (this.hasNewTeacherComment && this.userMode === 'S') || (this.hasNewTeacherCommentForAdmin));
  }

  get hasNewTeacherCommentForAdmin() {
    if (this.$currentUser.isAdmin) {
      return (this.adminItems || []).findIndex(item => {
        return CommonUtils.isTrue(item.newCommentT);
      }) > -1;
    }
    return false;
  }

  get floatingButtonIcon() {
    return this.$store.state.plans.remindersFloatingButtonIcon;
  }

  set floatingButtonIcon(val:string) {
    this.$store.commit('plans/setRemindersFloatingIcon', val);
  }

  get localDrawerRight() {
    return this.$store.state.drawer.drawerRight && this.drawerRightMode === 'account';
  }

  set localDrawerRight(v: boolean) {
    this.setDrawerRightMode('account');
    this.$nextTick(() => {
      this.setDrawerRight(v);
    })
  }

  get localDrawerRightAdd() {
    return this.$store.state.drawer.drawerRight && this.drawerRightMode === 'add';
  }

  set localDrawerRightAdd(v: boolean) {
    this.setDrawerRightMode('add');
    this.$nextTick(() => {
      this.setDrawerRight(v);
    });
  }

  get profilePhotoUrl() {
    try {
      if (this.userMode === 'S' && this.studentId > 0) {
        return this.studentPhotoUrl;
      } else {
        return this.userInfo.accountSettings.photoUrl;
      }
    } catch (e) {
      return '';
    }
  }

  gotoToday() {
    this.gotoLessonsEvents(new Date());
  }

  get canSwitchModes() {
    if (this.userMode === 'S') {
      return false;
    }
    if (
      this.userType === 'T' &&
      this.allowViewTeachers &&
      this.userMode !== 'S'
    ) {
      return ['plans', 'account'].includes(this.localPage);
    }
    return [
      'plans',
      'events',
      'grades',
      'lesson-banks',
      'messages',
      'school-years',
      'reporting',
      'students',
      'templates',
      'account',
      'lesson-layouts',
      'strategies',
      'sharing-options',
      'my-list',
      'school-list'
    ].includes(this.localPage);
  }

  get viewTypes() {
    return [
      { value: 'D', text: this.$t('dayLabel') },
      { value: 'W', text: this.$t('weekLabel') },
      { value: 'M', text: this.$t('monthLabel') },
      { value: 'C', text: this.$t('cycleLabel') },
      { value: 'L', text: this.$t('listLabel') }
    ].filter(v => this.showCycle || v.value !== 'C');
  }

  get hasProfilePhoto() {
    return CommonUtils.hasText(this.profilePhotoUrl);
  }

  get hasText() {
    return CommonUtils.hasText;
  }

  get studentTeacherId(): number {
    return +this.$store.state.settings.studentTeacherId;
  }

  set studentTeacherId(value: number) {
    this.$eventBus.$emit('studentTeacherChanged', value);
    this.$store.commit('settings/setStudentTeacherId', +value);
  }

  get currentYearId(): number {
    if (this.userMode === 'T') {
      return +this.$store.getters['settings/getCurrentSchoolYearId'];
    } else {
      return +this.$store.state.settings.currentYearId;
    }
  }

  get currentTeacherId(): number {
    if (this.userMode === 'T') {
      return +this.$store.state.settings.userId;
    } else {
      return +this.$store.state.settings.currentTeacherId;
    }
  }

  set currentTeacherId(value: number) {
    this.$store.commit('settings/setCurrentTeacherId', value);
  }

  get schoolYearItems() {
    if (this.userMode === 'A') {
      return this.getAdminYearItems(+this.currentTeacherId);
    } else {
      return this.teacherSchoolYearItems;
    }
  }

  get localSchoolYearItems() {
    return this.schoolYearItems.map(sy => {
      return {
        ...sy,
        type: sy.parentDistrictId > 0 ? 'D' : sy.parentSchoolId > 0 ? 'S' : ''
      }
    })
  }

  get schoolName() {
    return this.settings.schoolSettings.schoolName;
  }

  get localUserModeList() {
    return [
      {
        text: ['A', 'B', 'D'].includes(this.$currentUser.userType) ? this.$t('adminLabel') as string : this.$t('schoolLabel') as string,
        value: 'A'
      },
      {
        text: this.$t('teacherLabel') as string,
        value: 'T'
      }
    ];
  }

  get viewMonth() {
    return this.format(this.month, 'MMM YYYY', '... ....');
  }

  get displayViewStart() {
    if (this.viewType === 'C') {
      return DateTimeUtils.formatToDisplay(this.cycleDateRange.cycleStart);
    }
    return DateTimeUtils.formatToDisplay(this.weekDateRange.startDate);
  }

  get displayViewEnd() {
    if (this.viewType === 'C') {
      return DateTimeUtils.formatToDisplay(this.cycleDateRange.cycleEnd);
    }
    return DateTimeUtils.formatToDisplay(this.weekDateRange.endDate);
  }

  get viewStart() {
    if (this.viewType === 'C') {
      return this.format(
        this.cycleDateRange.cycleStart,
        this.localDateFormat,
        '... ..'
      );
    }
    return this.format(
      this.weekDateRange.startDate,
      this.localDateFormat,
      '... ..'
    );
  }

  get viewEnd() {
    if (this.viewType === 'C') {
      return this.format(
        this.cycleDateRange.cycleEnd,
        this.localDateFormat,
        '... ..'
      );
    }
    return this.format(
      this.weekDateRange.endDate,
      this.localDateFormat,
      '... ..'
    );
  }

  get localDateFormat() {
    return this.dateFormat === 2 ? 'D-MMM' : 'MMM D';
  }

  get isGenericView() {
    return CommonUtils.hasText(this.$route?.query.t) && CommonUtils.hasText(this.$route?.query.k);
  }

  get userSettingsLabel() {
    if (this.isGenericView) {
      if (this.$currentUser.isStudent) {
        if (CommonUtils.hasText(this.displayName)) {
          return this.displayName;
        } else if (CommonUtils.hasText(this.userInfo.accountSettings.firstName) && CommonUtils.hasText(this.userInfo.accountSettings.lastName)) {
          return this.userInfo.accountSettings.firstName + ' ' + this.userInfo.accountSettings.lastName;
        } else {
          return this.teacherEmail;
        }
      } else {
        return this.teacherEmail;
      }
    } else {
      return this.$t('userSettingsLabel');
    }
  }

  get showCycle() {
    return (
      (this.userMode === 'A' && this.isCurrentTeacherCycleSchedule) ||
      (this.userMode === 'T' && this.isCycleSchedule)
    );
  }

  get userModeFirstLetter() {
    for (let x = 0; x < this.localUserModeList.length; x++) {
      if (this.localUserModeList[x].value === this.userMode) {
        return this.localUserModeList[x].text.substr(0, 1);
      }
    }
    return '..';
  }

  get currentYearText() {
    for (let x = 0; x < this.schoolYearItems.length; x++) {
      if (this.schoolYearItems[x].value === this.currentYearId) {
        return this.schoolYearItems[x].text;
      }
    }
    return '...';
  }

  get isCompact() {
    return this.browserWidth < 1230;
  }

  get getCenterOffset() {
    if (this.browserWidth > 1385 || this.browserWidth < 1331 || this.isLeftDrawerMenuTypeHidden) return '';
    if (this.browserWidth < 1350) return 'margin-left: 20px';
    if (this.browserWidth < 1366) return 'margin-left: 16px';
    return 'margin-left: 8px';
  }

  get showDetails() {
    return this.$store.state.settings.showDetails;
  }

  set showDetails(value: boolean) {
    this.$store.commit('settings/setShowDetails', value);
    PlanbookStorage.set('showDetails', this.showDetails);
  }

  get localTitle() {
    return this.$route.name;
  }

  get localPage() {
    return CommonUtils.getPage(this.$route);
  }

  insideSchoolYear(year: any) {
    return DateTimeUtils.isBetween(this.currentDay, DateTimeUtils.getStartOfWeek(year.firstDay), DateTimeUtils.getEndOfWeek(year.lastDay));
  }

  get showTeacherStatusOptions() {
    return (this.$currentUser.isTeacher && this.currentYearId > 0 && this.schoolId !== 0 && this.getCurrentYear.parentYearId !== 0 && this.insideSchoolYear(this.getCurrentYear));
  }

  get showAdminStatusOptions() {
    return (this.$currentUser.isAdmin && this.currentSelectedSchoolYear.parentYearId && this.insideSchoolYear(this.currentSelectedSchoolYear));
  }

  get localAllSchoolStatus() {
    return this.allSchoolStatus
      .filter((s: any) => !s.overrideDelete)
      .map(s => {
        s.icon = this.selectedS.includes(s.schoolStatusId)
          ? 'fas fa-check-circle'
          : 'fas fa-circle';
        return s;
      });
  }

  get selectedS() {
    return this.schoolStatuses
      .filter((s: any) => s.type === 'W')
      .map((s: any) => s.schoolStatusId);
  }

  get schoolStatuses() {
    return this.getWeekSchoolStatus || [];
  }

  get haActiveStatus() {
    return (this.selectedS.includes(2) || this.selectedS.includes(1));
  }

  get activeStatusLabel() {
    if (this.selectedS.includes(2)) {
      return this.allSchoolStatus.find((s) => s.schoolStatusId === 2).statusText;
    } else if (this.selectedS.includes(1)) {
      return this.allSchoolStatus.find((s) => s.schoolStatusId === 1).statusText;
    } else {
      return 'Status';
    }
  }

  get activeStatusColor() {
    if (this.selectedS.includes(2)) {
      return this.allSchoolStatus.find((s) => s.schoolStatusId === 2).color;
    } else if (this.selectedS.includes(1)) {
      return this.allSchoolStatus.find((s) => s.schoolStatusId === 1).color;
    } else {
      return 'gray';
    }
  }

  get viewNotesText() {
    let text = '';
    try {
      if (this.$currentUser.isStudent) {
        if (this.$currentUser.id > 0) {
          if (CommonUtils.isNotEmpty(this.studentTeachers)) {
            this.studentTeachers.forEach(t => {
              const name = Teachers.getDisplayName(t);
              if (CommonUtils.hasText(t.viewNotesText)) {
                text += `<div>
              <span class="font-weight-bold">${name}: </span>
              <div class="d-inline-block">${t.viewNotesText}</div>  
            </div>`
              }
            })
          }
        } else if (this.currentTeacherId > 0) {
          const settings = this.$store.state.settings.settings[this.currentTeacherId];
          text += settings?.accountSettings?.viewNotesText || this.$currentUser.settings.accountSettings.viewNotesText;
        } else {
          text += this.$currentUser.settings.accountSettings.viewNotesText;
        }
      }
    } catch (e) {
    }
    return text;
  }

  get leftDrawerMenuType():number {
    return this.getDisplaySettings.leftDrawerMenuType;
  }

  get isLeftDrawerMenuTypeHidden(): boolean {
    return this.leftDrawerMenuType === 2;
  }

  get localCurrentSchoolYear() {
    const currentSchoolYear = this.localSchoolYearItems.find(s => s.value === this.currentYearId);
    return currentSchoolYear || { firstDay: '12/31/9999', lastDay: '12/31/9999' }
  }

  get isCurrentDateInSchoolYearRange() {
    const lastDay = moment(this.localCurrentSchoolYear.lastDay || '12/31/9999', 'MM/DD/YYYY');
    return moment().isSameOrBefore(lastDay);
  }

  get isBetween() {
    return DateTimeUtils.isBetween;
  }

  get getGlobalLanguage() {
    return this.globalLanguage || 'en';
  }

  focusButton(refName: string) {
    this.$nextTick(() => {
      new Promise(resolve => setTimeout(resolve, 100)).then(() => {
        try {
          const element = (this.$refs as any)[refName].$el;
          if (element.querySelector('button')) {
            element.querySelector('button').focus();
          } else {
            element.querySelector('a').focus();
          }
        } catch (e) {
          console.log(this.$t('focusFailed'));
        }
      })
    })
  }

  showViewNotesText() {
    return this.$refs.confirm.alert({
      title: this.$t('viewInformationLabel'),
      text: this.viewNotesText,
      option1ButtonAlternativeText: this.$t('okLabel')
    });
  }

  addRemoveSchoolStatus(status: any) {
    if (status.adminOnly && !this.$currentUser.isAdmin) {
      return;
    }
    if (status.schoolStatusId === 1 && this.selectedS.includes(1)) {
      this.$refs.confirm.confirm({
        title: this.$t('removeStatusLabel'),
        text: this.$t('removeReadyWarning', { name: status.statusText }),
        option1ButtonAlternativeText: this.$t('removeLabel')
      }).then((result) => {
        if (result === 1) {
          this.doAddRemoveStatus(status);
        }
      });
    } else {
      this.doAddRemoveStatus(status);
    }
  }

  doAddRemoveStatus(status: any) {
    const payload = {
      yearId: this.currentYearId,
      subjectId: 0,
      type: 'W',
      startDate: this.weekDateRange.startDate,
      schoolStatusIds: status.schoolStatusId
    };
    if (this.selectedS.includes(status.schoolStatusId)) {
      this.removeSchoolStatus(payload).then(() => {
        this.$eventBus.$emit('schoolStatusUpdate');
      });
    } else {
      this.applySchoolStatus(payload).then(() => {
        this.$eventBus.$emit('schoolStatusUpdate');
      });
    }
  }

  manageStatus() {
    this.showStatusMenu = false;
    this.$nextTick(() => {
      new Promise(resolve => setTimeout(resolve, 10)).then(() => {
        this.$refs.manageStatus.manageStatus();
      });
    });
  }

  openSchoolStatus() {
    this.showStatusMenu = false;
    this.$nextTick(() => {
      new Promise(resolve => setTimeout(resolve, 10)).then(() => {
        const viewStart =
          this.viewType === 'C'
            ? this.cycleDateRange.cycleStart
            : this.weekDateRange.startDate;
        this.$refs.applyStatus.openApplyStatus(viewStart);
      });
    });
  }

  messageTeacher() {
    this.$eventBus.$emit('openSubPage', {
      type: 'message',
      modal: true,
      width: 350,
      input: {
        action: 'reply',
        recipient: this.currentTeacherId
      }
    });
  }

  public hideRightDrawer() {
    if (this.localDrawerRight) {
      this.localDrawerRight = false;
    }
  }

  schoolYearChanged(param: any) {
    this.$eventBus.$emit('schoolYearChanged', param);
  }

  doChangeViewType(viewType: string) {
    this.changeViewType(viewType).then(result => {
      if (result.changed) {
        this.$eventBus.$emit('viewTypeChanged');
      }
    });
  }

  onPickerFirstDayChanged(value: string) {
    this.gotoDateMenu = false;
    this.gotoLessonsEvents(moment(value, 'YYYY-MM-DD').toDate());
  }

  format(dateString: string, format: string, defaultValue: string) {
    const date = moment(dateString, 'MM/DD/YYYY', true);
    if (date.isValid()) {
      return date.format(format);
    } else {
      return defaultValue;
    }
  }

  @Watch('gotoDateMenu')
  focusDatePicker() {
    if (this.gotoDateMenu) {
      this.$nextTick(() => {
        new Promise(resolve => setTimeout(resolve, 100)).then(() => {
          try {
            this.$refs.datePicker.$el.querySelector('.v-date-picker-header__value').querySelector('button').focus()
          } catch (e) {
            console.log(this.$t('focusFailed'));
          }
        });
      });
    }
  }

  hideRightDrawerAdd() {
    if (this.localDrawerRightAdd) {
      this.localDrawerRightAdd = false;
    }
  }

  showDrawerBottom() {
    this.setDrawerBottom(true);
  }

  showRemindersMobileDrawer() {
    this.setRemindersMobileDrawer(true);
    PlanbookStorage.set('reminders', true);
    this.floatingButtonIcon = 'fal fa-angle-right';
    if (this.remindersMobileClickCounter === 0) {
      this.setRemindersMobileClickCounter(1);
    } else if (this.remindersMobileClickCounter === 1) {
      this.setRemindersMobileClickCounter(2);
    } else if (this.remindersMobileClickCounter === 2) {
      this.setRemindersMobileClickCounter(0);
      this.setRemindersMobileDrawer(false);
      PlanbookStorage.set('reminders', false);
      this.floatingButtonIcon = 'fal fa-angle-left';
    }
  }

  openCopyPage() {
    this.$router.replace('/copy');
  }

  plansShowPrintOptions() {
    this.$refs.printOptions.defaultPrintViews();
    this.$refs.printOptions.print();
  }

  emitPrint() {
    this.$eventBus.$emit('showLocalPrintOptions');
  }

  async userModeChanged(param: string) {
    if (this.userMode !== param) {
      if (param === 'A' && this.localPage === 'my-list') {
        this.$router.replace('school-list');
      } else if (param === 'T' && this.localPage === 'school-list') {
        this.$router.replace('my-list');
      }
      await this.changeUserMode(param);
      this.$eventBus.$emit('userModeChanged', param);
    }
  }

  showorHideReminders() {
    if (PlanbookStorage.get('reminders')) {
      PlanbookStorage.set('reminders', false);
      this.$eventBus.$emit('closeSubPage');
      this.floatingButtonIcon = 'fal fa-angle-left';
    } else {
      PlanbookStorage.set('reminders', true);
      this.$eventBus.$emit('openSubPage', {
        type: 'reminder',
        width: 350
      });
      this.floatingButtonIcon = 'fal fa-angle-right';
    }
  }

  created() {
    this.$nextTick(() => {
      this.$eventBus.$on('appBarDisable', () => {
        this.localAppBarDisabled = true;
      });
      this.$eventBus.$on('appBarEnable', () => {
        this.localAppBarDisabled = false;
      });
      this.$eventBus.$on('plansToolbarShow', () => {
        this.localPlansToolbarHidden = false;
      });
      this.$eventBus.$on('plansToolbarHide', () => {
        this.localPlansToolbarHidden = true;
      });
      this.$eventBus.$on('printButtonShow', () => {
        this.localPrintButtonHidden = false;
      });
      this.$eventBus.$on('printButtonHide', () => {
        this.localPrintButtonHidden = true;
      });
      this.$eventBus.$on('openManageStatus', () => {
        this.manageStatus();
      });
    });
  }

  destroyed() {
    this.$eventBus.$off('appBarDisable');
    this.$eventBus.$off('appBarEnable');
    this.$eventBus.$off('plansToolbarShow');
    this.$eventBus.$off('plansToolbarHide');
    this.$eventBus.$off('printButtonShow');
    this.$eventBus.$off('printButtonHide');
    this.$eventBus.$off('openManageStatus');
  }

  onAddMenuClick() {
    if (this.$currentUser.isStudent && this.$currentUser.id > 0) {
      this.$eventBus.$emit('openSubPage', {
        type: 'joinClass',
        modal: true
      });
    } else {
      this.localDrawerRightAdd = !this.localDrawerRightAdd;
    }
  }

  onEditMenuClick() {
    if (this.$currentUser.isStudent && this.$currentUser.id > 0) {
      this.$eventBus.$emit('openSubPage', {
        type: 'manageClass',
        modal: true
      });
    } else {
      this.localDrawerRightAdd = !this.localDrawerRightAdd;
    }
  }
}
