var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    {
      staticStyle: {
        "font-family": "Raleway, Roboto, Arial, Helvetica, sans-serif",
        color: "#000000de",
      },
    },
    [
      _c(
        "v-container",
        { staticClass: "error-page fill-height", attrs: { tag: "section" } },
        [
          _c(
            "v-row",
            { staticClass: "text-center", attrs: { justify: "center" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "auto" } },
                [
                  _c("div", { staticClass: "display-4 mb-5 mt-10" }, [
                    _vm._v(" " + _vm._s(_vm.getErrorNum) + " "),
                  ]),
                  _c("div", { staticStyle: { width: "550px" } }, [
                    _c("div", [_vm._v(_vm._s(_vm.getErrorType))]),
                    _c("div", { staticClass: "mt-5" }, [
                      _vm._v(
                        _vm._s(_vm.getErrorDescription) +
                          " " +
                          _vm._s(_vm.$t("errorApologyMessage"))
                      ),
                    ]),
                  ]),
                  _c(
                    "v-btn",
                    {
                      staticClass: "mt-6 mr-6",
                      attrs: {
                        color: "primary",
                        depressed: "",
                        href: _vm.getPlansLink,
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("plansLabel")) + " ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "mt-6",
                      attrs: {
                        color: "primary",
                        depressed: "",
                        href: "https://planbook.com",
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("homeLabel")) + " ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }