<template>
  <v-app style="font-family: Raleway, Roboto, Arial, Helvetica, sans-serif; color: #000000de;">
    <v-container class="error-page fill-height" tag="section">
      <v-row class="text-center" justify="center">
        <v-col cols="auto">
          <div class="display-4 mb-5 mt-10">
            {{ getErrorNum}}
          </div>
          <div style="width: 550px;">
            <div>{{ getErrorType }}</div>
            <div class="mt-5">{{ getErrorDescription }} {{ $t('errorApologyMessage') }}</div>
          </div>
          <v-btn class="mt-6 mr-6" color="primary" depressed :href="getPlansLink">
            {{ $t('plansLabel') }}
          </v-btn>
          <v-btn class="mt-6" color="primary" depressed href="https://planbook.com">
            {{ $t('homeLabel') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
import { navigationItems } from '@/constants';

export default {
  name: 'PagesError',
  props: {
    error: {
      type: String,
      required: false,
      default: null
    }
  },
  computed: {
    getErrorNum () {
      if (this.error) return this.error;
      if (this.getPageInvalid) return '404';
      return '500';
    },
    getErrorType() {
      switch (this.getErrorNum) {
        case '403': return this.$t('error403');
        case '404': return this.$t('error404');
        default: return this.$t('error500');
      }
    },
    getErrorDescription() {
      switch (this.getErrorNum) {
        case '403': case '404': return this.$t('lostErrorMessage');
        default: return this.$t('defaultErrorMessage')
      }
    },
    getPageInvalid() {
      const goto = navigationItems.find((i) => (i.to === this.$route.path || (i.subitems && i.subitems.find((s) => s.to === this.$route.path))));
      return !goto;
    },
    getPlansLink() {
      return window.location.protocol + '//' + window.location.host + '/plans';
    }
  }
};
</script>

<style lang="sass">
.error-page
  h1
    font-size: 12rem
</style>
